:root {
    --black: black;
    --white: white;
    --light-grey: #888;
    --red: #f00;
    --green: #0f0;
    --blue: #00f;
    --pink: #f0f;
}

#snipcart {

}
