@font-face {
  font-family: 'Manifont Grotesk';
  src: url('../fonts/ManifontGroteskBook-webfont.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/ManifontGroteskBook-webfont.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/WorkSans-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/WorkSans-Regular.ttf')  format('truetype') /* Safari, Android, iOS */
}
