/* ----- Lines -------------------------------------------------------------- */
.shadow {
  border-width: 0px;
  border-color: transparent;
  border-style: solid;
  border-radius: .5em;
  box-shadow: var(--light-grey) 0 0 6px;
}

.shadow:hover,
.shadow:focus,
a.shadow:hover,
a.shadow:focus,
button.shadow:hover,
button.shadow:focus {
  box-shadow: var(--green) 0 0 6px;
  border-radius: .5em;
}

.shadow.no-hover:hover,
.shadow.no-hover:focus {
  border-radius: .5em;
  box-shadow: var(--light-grey) 0 0 6px;
}

.shadow.no-click:hover,
.shadow.no-click:focus {
  cursor: not-allowed;
}

hr {
  border: none;
  background-image: linear-gradient(90deg, var(--green) 60%, transparent 40%),
                    linear-gradient(90deg, var(--green) 60%, transparent 40%),
                    linear-gradient(180deg, var(--green) 60%, transparent 40%),
                    linear-gradient(180deg, var(--green) 60%, transparent 40%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 40px 4px, 40px 4px, 4px 40px, 4px 40px;
  height: 4px;
  margin: 1.5em 0;
}

.on-workshop hr {
  /* background-image: linear-gradient(90deg, var(--red) 60%, transparent 40%),
                    linear-gradient(90deg, var(--red) 60%, transparent 40%),
                    linear-gradient(180deg, var(--red) 60%, transparent 40%),
                    linear-gradient(180deg, var(--red) 60%, transparent 40%); */
  background-image: linear-gradient(90deg, var(--green) 60%, transparent 40%),
                    linear-gradient(90deg, var(--green) 60%, transparent 40%),
                    linear-gradient(180deg, var(--green) 60%, transparent 40%),
                    linear-gradient(180deg, var(--green) 60%, transparent 40%);
}

.on-event hr {
  /* background-image: linear-gradient(90deg, var(--blue) 60%, transparent 40%),
                    linear-gradient(90deg, var(--blue) 60%, transparent 40%),
                    linear-gradient(180deg, var(--blue) 60%, transparent 40%),
                    linear-gradient(180deg, var(--blue) 60%, transparent 40%); */
  background-image: linear-gradient(90deg, var(--green) 60%, transparent 40%),
                    linear-gradient(90deg, var(--green) 60%, transparent 40%),
                    linear-gradient(180deg, var(--green) 60%, transparent 40%),
                    linear-gradient(180deg, var(--green) 60%, transparent 40%);
}

/* .on-exhibition hr {
  background-image: linear-gradient(90deg, var(--pink) 60%, transparent 40%),
                    linear-gradient(90deg, var(--pink) 60%, transparent 40%),
                    linear-gradient(180deg, var(--pink) 60%, transparent 40%),
                    linear-gradient(180deg, var(--pink) 60%, transparent 40%);
} */

/* ----- End Lines ---------------------------------------------------------- */

/* ----- Menu --------------------------------------------------------------- */
.menu-head {
    height: 3.5em;
  padding: 0 4vw;
}

#menu-drop {
  padding: 0 4vw 1em;
}

.menu {
  position: absolute;
  background-color: white;
  width: 100%;
  z-index: 20;
  top: 0;
  right: 0;
  box-shadow: var(--light-grey) 0 0 6px
}

.menu-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-logo {
  width: 50%;
}

.menu-logo img {
  width: auto;
  max-height: 60px;
}

#menu-logo-full {
  display: none;
}

#menu-toggle {
  display: inline-block;
}

#menu-drop {
  padding-top: 1.25em;
  display: none;
  height: 35vh;
}

li.menu-item .active {
  box-shadow: var(--green) 0 0 6px;
}

#menu-drop li {
  display: inline-block;
}

#menu-toggle,
#menu-toggle a,
.menu-item,
.menu-item a {
  display: inline-block;
}

.menu-item {
  padding: .4em .65em .4em 0;
}
.menu-item:last-of-type {
  padding: .4em 0 .4em 0;
}

.submenu {
  padding-bottom: 1.25em;
}

@media only screen and (min-width: 980px) {
  #menu-drop {
    padding: 0;
  }

  .menu-head {
    padding: 1em .25vw 0;
  }

  .navigation {
    padding: 0 1.5vw 50px;
  }

  .menu {
    position: static;
    background-color: transparent;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menu-head {
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    display: inline-block;
  }

  .menu-logo {
    /* display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; */
    display: inline-block;
  }

  #menu-logo-circle {
    display: none;
  }

  #menu-logo-full {
    display: inline-block;
  }

  #menu-logo-full img {
    max-height: 77px;
  }

  #menu-toggle {
    display: none
  }

  #menu-drop {
    padding-top: .75em;
    display: inline-block;
    height: auto;
  }

  .submenu-item {
    display: inline-block;
    padding-right: .5em;
  }
}
/* ----- End Menu ----------------------------------------------------------- */

/* ----- About -------------------------------------------------------------- */
.section-about {
  background-image: linear-gradient(var(--green), transparent, var(--green));
  background: center / cover repeat url("../images/graindient_about.png");
}

.section-about p {
  line-height: 1.15em;
  padding: 4em 4.5vw;
}

.ace-logo img {
  max-height: 100px;
  max-width: 100%;
}

@media only screen and (min-width: 768px) {
  .section-about p {
    line-height: 1em;
    padding: 1.5em 8vw;
  }
}

/* ----- End About ---------------------------------------------------------- */

/* ----- Calendar ----------------------------------------------------------- */
.month {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.25em;
  align-items: flex-start;
  justify-content: space-between;
}

/* .month::after {
  content: "";
  flex: auto;
} */

.month-head {
  flex: 0 1 100%;
}

@media only screen and (min-width: 768px) {
  .main-home .month {
    padding: 3.5em calc(2.5em + 1.5vw) 2em;
  }

  .main .month {
    padding: 0 2.5em;
  }
}

/* ----- End Calendar ------------------------------------------------------- */

/* ----- Cards -------------------------------------------------------------- */
.card {
  margin-bottom: 3vw;
  display: inline-block;
}

.shadow.card-workshop-date:hover,
.shadow.card-workshop-date:focus {
  box-shadow: var(--green) 0 0 6px;
  border-radius: .5em;
}


.shadow.card-film:hover,
.shadow.card-film:focus {
  /* box-shadow: var(--red) 0 0 6px; */
  box-shadow: var(--green) 0 0 6px;
  border-radius: .5em;
}

.shadow.card-event:hover,
.shadow.card-event:focus {
  /* box-shadow: var(--blue) 0 0 6px; */
  box-shadow: var(--green) 0 0 6px;
  border-radius: .5em;
}

.card-exhibition .card-img,
.on-exhibition .on-img {
  /* background-color: var(--pink); */
  /* background-color: var(--blue); */
  box-shadow: var(--green) 0 0 6px;
}

.shadow.card-exhibition:hover,
.shadow.card-exhibition:focus {
  /* box-shadow: var(--pink) 0 0 6px; */
  /* box-shadow: var(--blue) 0 0 6px; */
  box-shadow: var(--green) 0 0 6px;
  border-radius: .5em;
}

.no-tint {
  background-color: transparent !important;
}

.card a {
  width: 100%;
}

.upcoming .card-img-wrapper {
  display: block;
  width: 100%;
}

.card-img,
.upcoming .card-img {
  height: 30vw;
  width: 90vw;
  width: 100%;
  max-width: 100%;
  background-size: cover;
  background-position: center;
  background-blend-mode: luminosity;
  border-radius: .5em .5em 0 0;
}

.upcoming .card-img {
  width: 100%;
  border-radius: 0 0 .5em .5em;
}

.card-text {
  padding: .85em 1em .75em 1em;
}

.upcoming .card-text {
  display: flex;
}

.card-text-date {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .25em .5em;
  border: solid 1px black;
  border-radius: .5em;
  margin-right: 1em;
}

.card-text-date span:nth-of-type(2) {
  font-size: 1.5em;
}

.detail-date {
  display: flex;
  padding-bottom: 1em;
  align-items: center;
}

.card-text-dates .multi-date {
  display: block;
  width: 100%;
  text-align: center;
  padding-right: 1em;
}

.upcoming .card-text-info {
  display: flex;
  flex-direction: column;
}

.upcoming .card a {
  display: flex;
  flex-direction: column-reverse;
}

.upcoming .card-img {
  border-radius: .5em .5em 0 0;
}

.on-type {
  display: flex;
  align-items: center;
  padding-top: .5em;
}

.on-type * {
  padding-right: .65em;
}

.on-type *:first-child {
  padding-right: .2em;
}

.on-type img,
.on-type p {
  display: inline-block;
}

.on-type img {
  height: 1em;
  width: auto;
}

.on-type p.tag {
  color: white;
  background: black;
  padding: .1em .35em;
  border-radius: .25em;
}

@media only screen and (min-width: 768px) {
  /* .month .card:nth-child(odd) {
    margin-right: 3vw;
  } */

  .card {
    flex: 0 1 47%;
  }

  .upcoming .card {
    flex: 0 1 auto;
  }

  .upcoming .card a {
    flex-direction: row;
  }

  .upcoming .card-info {
    width: 50%;
  }

  .card-img {
    /* width: 40vw; */
    height: 26.5vw;
    max-width: 100%;
  }

  /* .up-next .card-img {
    height: 40vw;
    width: 70vw;
    max-width: 100%;
  } */

  .upcoming .card-img-wrapper {
    display: inline-block;
    width: 50%;
  }

  .upcoming .card-img {
    width: 50vw;
    height: 100%;
    border-radius: 0 .5em .5em 0
  }
}

/* ----- End Cards ---------------------------------------------------------- */

/* ----- On Page ------------------------------------------------------------ */
.on-img {
  /* height: 50vw;
  width: 96vw;
  max-width: 100%;
  background-size: cover;
  background-position: center;
  background-blend-mode: luminosity; */
  border-radius: .5em;
  margin-bottom: 1.75em;
}

@media only screen and (min-width: 768px) {
  .on-img img {
    /* height: 37vw;
    width: 66vw;
    max-width: 100%;
    min-width: 646px;
    min-height: 360px; */
    height: auto;
    width: 100%;
    border-radius: .5em;
    display: block;
  }
}
/* ----- End On Page -------------------------------------------------------- */

/* ----- Item Details ------------------------------------------------------- */
.detail-list li {
  list-style: none;
}

/* .detail-symbol {
  height: 1em;
  width: auto;
  padding-right: .5em;
} */

@media only screen and (min-width: 768px) {
  ul.detail-list,
  div.book-buttons {
    width: 66vw;
    margin-bottom: .5em;
  }

  /* .detail-list li {
    display: inline-flex;
    width: 45%;
  }

  .detail-list li:nth-of-type(odd) {
    margin-right: 5%;
  } */
}

/* ----- End Item Details --------------------------------------------------- */

/* ----- Text Pages --------------------------------------------------------- */
.gallery {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.75em;
}

.gallery div {
  height: 260px;
  height: 70vw;
  width: 100%;
  margin-bottom: 1.5em;
}

.section-text {
  margin-bottom: 2.75em;
}

.section-hr {
  height: 50px;
  width: 100%;
  margin-bottom: 2.75em;
}
.section-hr div {
  display: inline-block;
  height: 100%;
  width: 50%;
}

.section-hr .green {
  border-radius: 5px 0 0 5px;
  background-image: linear-gradient(var(--green), transparent);
  background: center / contain repeat url("../images/graindient_footer_green.png");
}

.section-hr .black {
  border-radius: 0 5px 5px 0;
  background-image: linear-gradient(transparent, var(--black));
  background: center / contain repeat url("../images/graindient_footer_black.png");
}

@media only screen and (min-width: 768px) {
  .gallery {
    flex-direction: row;
  }

  .gallery div {
    flex: 1 1 auto;
    height: 240px;
    height: 20vw;
    width: 100%;
    margin-right: 2.5%;
    margin-bottom: 0;
  }

  .gallery div:last-of-type {
    margin-right: 0;
  }
}

/* ----- End Text Pages ----------------------------------------------------- */

.arrow-navigation {
  padding-top: 1.5em;
}

/* ----- Footer ------------------------------------------------------------- */
.footer-connect {
  background-image: linear-gradient(transparent, var(--black));
  background: center / cover repeat url("../images/graindient_footer_black.png");
}

.footer-logo {
  background-image: linear-gradient(var(--green), transparent);
  background: center / cover repeat url("../images/graindient_footer_green.png");
}

a.connect {
  background-color: white;
  /* margin-right: .5em;
  margin-bottom: .5em; */
  align-self: flex-end;
}

a.connect svg {
  display: block;
  height: 1.25em;
  width: auto;
  margin: .5em;
}

/* #mc-embedded-subscribe-form {
  align-self: flex-start;
} */

#mce-EMAIL,
#mc-embedded-subscribe {
  padding: .75em;
  font-size: 1rem;
}

div#real {
  height: 0;
  position: absolute;
  left: -5000px
}

#mce-EMAIL {
  border-radius: .5em 0 0 .5em;
  width: 80vw;
}

#mc-embedded-subscribe {
  border-radius: 0 .5em .5em 0;
  color: white;
  background-color: var(--black);
}

@media only screen and (min-width: 768px) {
  .footer-connect {
    background-image: linear-gradient(transparent, var(--black));
    background: center / cover repeat url("../images/graindient_footer_black.png");
  }

  .footer-logo {
    background-image: linear-gradient(var(--green), transparent);
    background: center / cover repeat url("../images/graindient_footer_green.png");
  }

  a.connect,
  #mc-embedded-subscribe-form {
    align-self: auto;
    margin-bottom: 0;
  }

  #mce-EMAIL,
  #mc-embedded-subscribe {
    padding: 1em 1.35em;
  }

  #mce-EMAIL {
    width: 39vw;
  }
}

/* ----- End Footer --------------------------------------------------------- */
