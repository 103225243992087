@charset "UTF-8";

@font-face {
  font-family: 'Manifont Grotesk';
  src: url('../fonts/ManifontGroteskBook-webfont.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/ManifontGroteskBook-webfont.ttf')  format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/WorkSans-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/WorkSans-Regular.ttf')  format('truetype') /* Safari, Android, iOS */
}

/* ----- Reset -------------------------------------------------------------- */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article,
aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline
}

html {
  line-height: 1
}

ol, ul {
  list-style: none
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle
}

q, blockquote {
  quotes: none
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none
}

a img {
  border: none
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block
}

/* ----- End Reset ---------------------------------------------------------- */

/*
   reset
   — https://github.com/jongacnik/recsst
         */

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
h1, h2, h3, h4, h5, h6,
p,
ol, ul,
li,
dl,
dt, dd,
figure,
blockquote,
button {
  margin: 0;
  padding: 0;
}

button {
  border: 0;
  color: inherit;
  background-color: inherit;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
}

:focus {
  outline: none;
}

:root {
    --black: black;
    --white: white;
    --light-grey: #888;
    --red: #f00;
    --green: #0f0;
    --blue: #00f;
    --pink: #f0f;
}

#snipcart {

}

:root {
  --ft-body: 'Work Sans', sans-serif;
  --ft-head: 'Manifont Grotesk', serif;
  --ft-a: 18px;
  --ft-lh: 1;
	--baseline: calc(var(--ft-a) * 1.2);
  --border: 1px solid var(--black);
}

body,
#mce-EMAIL,
#mc-embedded-subscribe, {
  font-family: var(--ft-body);
  font-feature-settings: "liga" 1;
  font-size: 20px;
  line-height: 1.30em;
}

.snip-layout {
  font-family: var(--ft-body) !important;
}

.ft-small {
  font-size: 14px;
  line-height: 20px;
}

h1,
h2,
h3,
.text-intro,
.text-intro p {
  font-family: var(--ft-head);
  line-height: 1.15em;
  margin-bottom: .75em;
  font-feature-settings: "liga" 0;
}

h1,
.month-head  {
  font-size: 46px;
  line-height: 1.15em;
}

.text-intro {
  margin-bottom: 1.5em;
}

h2,
.text-intro {
  font-size: 23px;
  line-height: 1.15em;
}

.section-about {
  font-size: 23px;
  font-family: var(--ft-head);
  line-height: 1.15em;
  font-feature-settings: "liga" 0;
}

h3 {
  font-size: 23px;
  line-height: 1.15em;
}

a,
a:visited {
  color: var(--black);
  text-decoration-color: var(--blue);
}

a:hover,
a:focus {
  color: var(--blue);
  text-decoration-color: var(--blue);
  cursor: pointer;
}

a.no-a-style,
a.no-a-style:hover,
a.no-a-style:focus {
  color: var(--black);
  text-decoration: none !important;
}

@media only screen and (min-width: 768px) {
  body {
    font-family: var(--ft-body);
    font-size: 24px;
    line-height: 1.15em;
  }

  .ft-small {
    font-size: 20px;
    line-height: 24px;
  }

  h1,
  .section-about,
  .month-head {
    font-size: 62px;
  }

  h2,
  .text-intro {
    font-size: 42px;
  }

  h3 {
    font-size: 28px;
  }
}

/* ----- Menu --------------------------------------------------------------- */

.menu-item,
.submenu-item,
#menu-toggle {
  font-family: var(--ft-head);
  font-feature-settings: "liga" 0;
}

.menu-item a,
#menu-toggle a {
  font-size: 20px;
  padding: .7em .75em .6em;
  text-decoration: none;
  color: var(--black);
}

.submenu-item a,
.submenu-item a:visited {
  font-size: 20px;
  /* padding: 1em .75em .85em .75em; */
  display: inline-block;
  color: var(--black);
  text-decoration: underline;
  text-decoration-color: var(--blue);
}

.menu-item a:hover,
.menu-item a:focus,
.menu-item a:visited {
  color: var(--black);
}

.submenu-item a:hover,
.submenu-item a:focus {
  color: var(--blue);
}

.submenu-item a:hover,
.submenu-item a:focus {
  color: var(--blue);
  text-decoration-color: var(--blue);
}

@media only screen and (min-width: 768px) {
  .menu-item a,
  .submenu-item a {
    font-size: 24px;
  }
  .menu-item a,
  #menu-toggle a {
    padding: .75em .75em .6em;
  }
}

/* ----- End Menu ----------------------------------------------------------- */

.button {
  padding: .85em .75em .85em .75em;
  display: inline-block;
  color: var(--black);
  margin-bottom: 1.5em;
}

.button:hover,
.button:focus,
.button:visited {
  color: var(--black);
}

/* ----- Section Tags ------------------------------------------------------- */
.section-tag {
  position: absolute;
  display: inline-block;
  font-family: var(--ft-body);
  border: var(--border);
  border-radius: .5em;
  padding: .25em .55em;
  margin: 2em 0 0 4.5vw;
}

@media only screen and (min-width: 768px) {
  .section-tag {
    margin: 1.5vw 0 0 1.5vw;
  }
}

/* ----- End Section Tags --------------------------------------------------- */

/* ----- Cards -------------------------------------------------------------- */
h2.month-head {
  margin-bottom: .45em;
}

.card-text h2 {
  margin-bottom: .25em;
}
/* ----- End Cards ---------------------------------------------------------- */

/* ----- Kirbytext ---------------------------------------------------------- */
.kt p {
  margin-bottom: 1em;
  line-height: 1.25em;
}

.kt h1,
.kt h2 {
  font-family: var(--ft-head);
  margin-bottom: .5em;
  font-feature-settings: "liga" 0;
}

.kt a {
  text-decoration: underline;
}

.kt > ul,
.kt > ol {
  margin-bottom: 1.5em;
}

.kt ul {
  /* list-style: none; */
  list-style: disc;
  list-style-position: inside;
}

.kt ul li {
  /* display: table-row; */
  margin-bottom: 1em;
}

/* .kt ul li:before {
  display: table-cell;
  text-align: right;
  padding: 0 .5em 0 0;
  content: "\25CF";
} */

pre, code {
  white-space: pre;
  white-space: pre-wrap;
  font-family: 'SF Mono';
  font-size: 95%;
}

.kt pre {
  border: 1px solid red;
  display: block;
  padding: .5em;
}

.ls--n:before {
  padding: 0;
  content: '';
}

.kt ol {
  list-style: none;
  display: table;
}

.kt ol li {
  display: table-row;
  counter-increment: number-list;
}

.kt ol li:before {
  content: counter(number-list) ".";
  display: table-cell;
  text-align: right;
  margin: 0 0 0 -2rem;
  width: 2rem;
  padding: 0 .5em 0 0;
}

@media all and (min-width: 500px) {

}
/* ----- End Kirbytext ------------------------------------------------------ */

body {
  /* padding: 3vw; */
}

.main {
  padding: 5em 4vw 5em;
  overflow-x: hidden;
}

.main-home {
  padding: 4.8em 0 0 0;
}

.text {
  width: 100%;
}

@media only screen and (min-width: 980px) {
  .main {
    padding: 0 1.5vw 8em;
  }

  .main-home {
    padding: 0;
  }

  .text {
    width: 50%;
    width: 50vw;
    max-width: 800px;
    min-width: 648px;
  }
}

/* ----- Home --------------------------------------------------------------- */
.up-next {
  display: flex;           /* establish flex container */
  flex-direction: row;  /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center;
  padding-top: 4.5em;
}

.up-next .card {
  margin: 0 4.5vw 1em 4.5vw;
}

.section-feed {
  background-color: #f2f2f2;
}

@media only screen and (min-width: 768px) {
  .up-next {
    padding-top: 0;
  }

  .up-next .card {
    margin: 0 0 4.5em 0;
  }
}

/* ----- End Home ----------------------------------------------------------- */

/* ----- Calendar ----------------------------------------------------------- */
.nothing-on {
  height: 400px;
  height: 50vh;
}

.month {
  display: flex;
  flex-direction: column;
}

.month .card {
  margin-bottom: 2em;
  display: inline-block;
}

.month .card:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .month {
    flex-direction: row;
  }

  .month .card {
    margin-bottom: 2em;
  }

  .on-item {
    /* width: 44.5%; */
    flex: 1 1 auto;
  }

  .instagram-post:last-child {
    padding: 0;
  }

  .instagram-post:nth-child(even) {
    margin: 0 3em;
  }
}

/* ----- End Calendar ------------------------------------------------------- */

/* ----- Workshop ----------------------------------------------------------- */

.on-workshop-flex {
  display: flex;
  flex-direction: column-reverse;
}

.on-workshops {

}

.on-workshop-info {

}

@media only screen and (min-width: 768px) {
  .on-workshop-flex {
    flex-direction: row;
  }

  .on-workshops {
    position: -webkit-sticky;
    position: sticky;
    top: 6em;
    padding-left: 1em;
    width: calc(50% - 1em);

  }

  .on-workshop-info {
    width: calc(50% - 1em);
    padding-right: 1em;
    max-width: 800px;
    min-width: 648px;
  }
}

/* ----- End Workshop ------------------------------------------------------- */

/* ----- Hire --------------------------------------------------------------- */
.hire-items {
  display: flex;
  flex-direction: column;
  padding: 0 0 2.75em 0;
}

.hire-item {
  width: 100%;
  padding: 1em 3.25vw 1.5em;
  margin-bottom: 4.25vw;
  display: inline-block;
}

.hire-cost {
  padding-bottom: 1em;
  border-bottom: dashed grey 1px;
}

@media only screen and (min-width: 500px) {
  .hire-items {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .hire-items .hire-item {
    margin-right: 4.25vw;
  }

  .hire-items .hire-item:nth-child(2n) {
    margin-right: 0vw;
  }

  .hire-item {
    flex: 0 1 47.4%;
  }
}

@media only screen and (min-width: 1200px) {
  .hire-items .hire-item:nth-child(2n) {
    margin-right: 4.25vw;
  }

  .hire-items .hire-item:nth-child(3n) {
    margin-right: 0;
  }

  .hire-item {
    flex: 0 1 28.95%;
  }
}

/* ----- End Hire ----------------------------------------------------------- */

/* ----- Footer ------------------------------------------------------------- */
.footer {
  display: flex;
  flex-direction: column;
  border-top: var(--border);
}

.footer > div {
  width: 100%;
  height: 50vh;
}

.footer-connect-links,
.footer-logo,
#mc_embed_signup_scroll {
  display: flex;           /* establish flex container */
  flex-direction: row;  /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center;
  flex-wrap: wrap;
}

.footer-connect-links {
    padding: 0 0.5em;
    grid-gap: 0.5em;
    height: inherit;
    align-content: center;
}

#mc_embed_signup_scroll {
  height: auto;
}

.footer-logo img {
  width: 60%;
  padding-bottom: 3%;
  height: auto;
}

@media only screen and (min-width: 768px) {
  .footer {
    flex-direction: row;
  }

  .footer > div {
    width: 50%;
    flex: 1 1 auto;
    /* max-height: 400px; */
  }


}

/* ----- End Footer --------------------------------------------------------- */

/* ----- Lines -------------------------------------------------------------- */
.shadow {
  border-width: 0px;
  border-color: transparent;
  border-style: solid;
  border-radius: .5em;
  box-shadow: var(--light-grey) 0 0 6px;
}

.shadow:hover,
.shadow:focus,
a.shadow:hover,
a.shadow:focus,
button.shadow:hover,
button.shadow:focus {
  box-shadow: var(--green) 0 0 6px;
  border-radius: .5em;
}

.shadow.no-hover:hover,
.shadow.no-hover:focus {
  border-radius: .5em;
  box-shadow: var(--light-grey) 0 0 6px;
}

.shadow.no-click:hover,
.shadow.no-click:focus {
  cursor: not-allowed;
}

hr {
  border: none;
  background-image: linear-gradient(90deg, var(--green) 60%, transparent 40%),
                    linear-gradient(90deg, var(--green) 60%, transparent 40%),
                    linear-gradient(180deg, var(--green) 60%, transparent 40%),
                    linear-gradient(180deg, var(--green) 60%, transparent 40%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 40px 4px, 40px 4px, 4px 40px, 4px 40px;
  height: 4px;
  margin: 1.5em 0;
}

.on-workshop hr {
  /* background-image: linear-gradient(90deg, var(--red) 60%, transparent 40%),
                    linear-gradient(90deg, var(--red) 60%, transparent 40%),
                    linear-gradient(180deg, var(--red) 60%, transparent 40%),
                    linear-gradient(180deg, var(--red) 60%, transparent 40%); */
  background-image: linear-gradient(90deg, var(--green) 60%, transparent 40%),
                    linear-gradient(90deg, var(--green) 60%, transparent 40%),
                    linear-gradient(180deg, var(--green) 60%, transparent 40%),
                    linear-gradient(180deg, var(--green) 60%, transparent 40%);
}

.on-event hr {
  /* background-image: linear-gradient(90deg, var(--blue) 60%, transparent 40%),
                    linear-gradient(90deg, var(--blue) 60%, transparent 40%),
                    linear-gradient(180deg, var(--blue) 60%, transparent 40%),
                    linear-gradient(180deg, var(--blue) 60%, transparent 40%); */
  background-image: linear-gradient(90deg, var(--green) 60%, transparent 40%),
                    linear-gradient(90deg, var(--green) 60%, transparent 40%),
                    linear-gradient(180deg, var(--green) 60%, transparent 40%),
                    linear-gradient(180deg, var(--green) 60%, transparent 40%);
}

/* .on-exhibition hr {
  background-image: linear-gradient(90deg, var(--pink) 60%, transparent 40%),
                    linear-gradient(90deg, var(--pink) 60%, transparent 40%),
                    linear-gradient(180deg, var(--pink) 60%, transparent 40%),
                    linear-gradient(180deg, var(--pink) 60%, transparent 40%);
} */

/* ----- End Lines ---------------------------------------------------------- */

/* ----- Menu --------------------------------------------------------------- */
.menu-head {
    height: 3.5em;
  padding: 0 4vw;
}

#menu-drop {
  padding: 0 4vw 1em;
}

.menu {
  position: absolute;
  background-color: white;
  width: 100%;
  z-index: 20;
  top: 0;
  right: 0;
  box-shadow: var(--light-grey) 0 0 6px
}

.menu-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-logo {
  width: 50%;
}

.menu-logo img {
  width: auto;
  max-height: 60px;
}

#menu-logo-full {
  display: none;
}

#menu-toggle {
  display: inline-block;
}

#menu-drop {
  padding-top: 1.25em;
  display: none;
  height: 35vh;
}

li.menu-item .active {
  box-shadow: var(--green) 0 0 6px;
}

#menu-drop li {
  display: inline-block;
}

#menu-toggle,
#menu-toggle a,
.menu-item,
.menu-item a {
  display: inline-block;
}

.menu-item {
  padding: .4em .65em .4em 0;
}
.menu-item:last-of-type {
  padding: .4em 0 .4em 0;
}

.submenu {
  padding-bottom: 1.25em;
}

@media only screen and (min-width: 980px) {
  #menu-drop {
    padding: 0;
  }

  .menu-head {
    padding: 1em .25vw 0;
  }

  .navigation {
    padding: 0 1.5vw 50px;
  }

  .menu {
    position: static;
    background-color: transparent;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menu-head {
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    display: inline-block;
  }

  .menu-logo {
    /* display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; */
    display: inline-block;
  }

  #menu-logo-circle {
    display: none;
  }

  #menu-logo-full {
    display: inline-block;
  }

  #menu-logo-full img {
    max-height: 77px;
  }

  #menu-toggle {
    display: none
  }

  #menu-drop {
    padding-top: .75em;
    display: inline-block;
    height: auto;
  }

  .submenu-item {
    display: inline-block;
    padding-right: .5em;
  }
}
/* ----- End Menu ----------------------------------------------------------- */

/* ----- About -------------------------------------------------------------- */
.section-about {
  background-image: linear-gradient(var(--green), transparent, var(--green));
  background: center / cover repeat url("../images/graindient_about.png");
}

.section-about p {
  line-height: 1.15em;
  padding: 4em 4.5vw;
}

.ace-logo img {
  max-height: 100px;
  max-width: 100%;
}

@media only screen and (min-width: 768px) {
  .section-about p {
    line-height: 1em;
    padding: 1.5em 8vw;
  }
}

/* ----- End About ---------------------------------------------------------- */

/* ----- Calendar ----------------------------------------------------------- */
.month {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.25em;
  align-items: flex-start;
  justify-content: space-between;
}

/* .month::after {
  content: "";
  flex: auto;
} */

.month-head {
  flex: 0 1 100%;
}

@media only screen and (min-width: 768px) {
  .main-home .month {
    padding: 3.5em calc(2.5em + 1.5vw) 2em;
  }

  .main .month {
    padding: 0 2.5em;
  }
}

/* ----- End Calendar ------------------------------------------------------- */

/* ----- Cards -------------------------------------------------------------- */
.card {
  margin-bottom: 3vw;
  display: inline-block;
}

.shadow.card-workshop-date:hover,
.shadow.card-workshop-date:focus {
  box-shadow: var(--green) 0 0 6px;
  border-radius: .5em;
}


.shadow.card-film:hover,
.shadow.card-film:focus {
  /* box-shadow: var(--red) 0 0 6px; */
  box-shadow: var(--green) 0 0 6px;
  border-radius: .5em;
}

.shadow.card-event:hover,
.shadow.card-event:focus {
  /* box-shadow: var(--blue) 0 0 6px; */
  box-shadow: var(--green) 0 0 6px;
  border-radius: .5em;
}

.card-exhibition .card-img,
.on-exhibition .on-img {
  /* background-color: var(--pink); */
  /* background-color: var(--blue); */
  box-shadow: var(--green) 0 0 6px;
}

.shadow.card-exhibition:hover,
.shadow.card-exhibition:focus {
  /* box-shadow: var(--pink) 0 0 6px; */
  /* box-shadow: var(--blue) 0 0 6px; */
  box-shadow: var(--green) 0 0 6px;
  border-radius: .5em;
}

.no-tint {
  background-color: transparent !important;
}

.card a {
  width: 100%;
}

.upcoming .card-img-wrapper {
  display: block;
  width: 100%;
}

.card-img,
.upcoming .card-img {
  height: 30vw;
  width: 90vw;
  width: 100%;
  max-width: 100%;
  background-size: cover;
  background-position: center;
  background-blend-mode: luminosity;
  border-radius: .5em .5em 0 0;
}

.upcoming .card-img {
  width: 100%;
  border-radius: 0 0 .5em .5em;
}

.card-text {
  padding: .85em 1em .75em 1em;
}

.upcoming .card-text {
  display: flex;
}

.card-text-date {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .25em .5em;
  border: solid 1px black;
  border-radius: .5em;
  margin-right: 1em;
}

.card-text-date span:nth-of-type(2) {
  font-size: 1.5em;
}

.detail-date {
  display: flex;
  padding-bottom: 1em;
  align-items: center;
}

.card-text-dates .multi-date {
  display: block;
  width: 100%;
  text-align: center;
  padding-right: 1em;
}

.upcoming .card-text-info {
  display: flex;
  flex-direction: column;
}

.upcoming .card a {
  display: flex;
  flex-direction: column-reverse;
}

.upcoming .card-img {
  border-radius: .5em .5em 0 0;
}

.on-type {
  display: flex;
  align-items: center;
  padding-top: .5em;
}

.on-type * {
  padding-right: .65em;
}

.on-type *:first-child {
  padding-right: .2em;
}

.on-type img,
.on-type p {
  display: inline-block;
}

.on-type img {
  height: 1em;
  width: auto;
}

.on-type p.tag {
  color: white;
  background: black;
  padding: .1em .35em;
  border-radius: .25em;
}

@media only screen and (min-width: 768px) {
  /* .month .card:nth-child(odd) {
    margin-right: 3vw;
  } */

  .card {
    flex: 0 1 47%;
  }

  .upcoming .card {
    flex: 0 1 auto;
  }

  .upcoming .card a {
    flex-direction: row;
  }

  .upcoming .card-info {
    width: 50%;
  }

  .card-img {
    /* width: 40vw; */
    height: 26.5vw;
    max-width: 100%;
  }

  /* .up-next .card-img {
    height: 40vw;
    width: 70vw;
    max-width: 100%;
  } */

  .upcoming .card-img-wrapper {
    display: inline-block;
    width: 50%;
  }

  .upcoming .card-img {
    width: 50vw;
    height: 100%;
    border-radius: 0 .5em .5em 0
  }
}

/* ----- End Cards ---------------------------------------------------------- */

/* ----- On Page ------------------------------------------------------------ */
.on-img {
  /* height: 50vw;
  width: 96vw;
  max-width: 100%;
  background-size: cover;
  background-position: center;
  background-blend-mode: luminosity; */
  border-radius: .5em;
  margin-bottom: 1.75em;
}

@media only screen and (min-width: 768px) {
  .on-img img {
    /* height: 37vw;
    width: 66vw;
    max-width: 100%;
    min-width: 646px;
    min-height: 360px; */
    height: auto;
    width: 100%;
    border-radius: .5em;
    display: block;
  }
}
/* ----- End On Page -------------------------------------------------------- */

/* ----- Item Details ------------------------------------------------------- */
.detail-list li {
  list-style: none;
}

/* .detail-symbol {
  height: 1em;
  width: auto;
  padding-right: .5em;
} */

@media only screen and (min-width: 768px) {
  ul.detail-list,
  div.book-buttons {
    width: 66vw;
    margin-bottom: .5em;
  }

  /* .detail-list li {
    display: inline-flex;
    width: 45%;
  }

  .detail-list li:nth-of-type(odd) {
    margin-right: 5%;
  } */
}

/* ----- End Item Details --------------------------------------------------- */

/* ----- Text Pages --------------------------------------------------------- */
.gallery {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.75em;
}

.gallery div {
  height: 260px;
  height: 70vw;
  width: 100%;
  margin-bottom: 1.5em;
}

.section-text {
  margin-bottom: 2.75em;
}

.section-hr {
  height: 50px;
  width: 100%;
  margin-bottom: 2.75em;
}
.section-hr div {
  display: inline-block;
  height: 100%;
  width: 50%;
}

.section-hr .green {
  border-radius: 5px 0 0 5px;
  background-image: linear-gradient(var(--green), transparent);
  background: center / contain repeat url("../images/graindient_footer_green.png");
}

.section-hr .black {
  border-radius: 0 5px 5px 0;
  background-image: linear-gradient(transparent, var(--black));
  background: center / contain repeat url("../images/graindient_footer_black.png");
}

@media only screen and (min-width: 768px) {
  .gallery {
    flex-direction: row;
  }

  .gallery div {
    flex: 1 1 auto;
    height: 240px;
    height: 20vw;
    width: 100%;
    margin-right: 2.5%;
    margin-bottom: 0;
  }

  .gallery div:last-of-type {
    margin-right: 0;
  }
}

/* ----- End Text Pages ----------------------------------------------------- */

.arrow-navigation {
  padding-top: 1.5em;
}

/* ----- Footer ------------------------------------------------------------- */
.footer-connect {
  background-image: linear-gradient(transparent, var(--black));
  background: center / cover repeat url("../images/graindient_footer_black.png");
}

.footer-logo {
  background-image: linear-gradient(var(--green), transparent);
  background: center / cover repeat url("../images/graindient_footer_green.png");
}

a.connect {
  background-color: white;
  /* margin-right: .5em;
  margin-bottom: .5em; */
  align-self: flex-end;
}

a.connect svg {
  display: block;
  height: 1.25em;
  width: auto;
  margin: .5em;
}

/* #mc-embedded-subscribe-form {
  align-self: flex-start;
} */

#mce-EMAIL,
#mc-embedded-subscribe {
  padding: .75em;
  font-size: 1rem;
}

div#real {
  height: 0;
  position: absolute;
  left: -5000px
}

#mce-EMAIL {
  border-radius: .5em 0 0 .5em;
  width: 80vw;
}

#mc-embedded-subscribe {
  border-radius: 0 .5em .5em 0;
  color: white;
  background-color: var(--black);
}

@media only screen and (min-width: 768px) {
  .footer-connect {
    background-image: linear-gradient(transparent, var(--black));
    background: center / cover repeat url("../images/graindient_footer_black.png");
  }

  .footer-logo {
    background-image: linear-gradient(var(--green), transparent);
    background: center / cover repeat url("../images/graindient_footer_green.png");
  }

  a.connect,
  #mc-embedded-subscribe-form {
    align-self: auto;
    margin-bottom: 0;
  }

  #mce-EMAIL,
  #mc-embedded-subscribe {
    padding: 1em 1.35em;
  }

  #mce-EMAIL {
    width: 39vw;
  }
}

/* ----- End Footer --------------------------------------------------------- */

#snipcart,
.snipcart {
    font-family: var(--ft-body) !important;
}

