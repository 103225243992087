:root {
  --ft-body: 'Work Sans', sans-serif;
  --ft-head: 'Manifont Grotesk', serif;
  --ft-a: 18px;
  --ft-lh: 1;
	--baseline: calc(var(--ft-a) * 1.2);
  --border: 1px solid var(--black);
}

body,
#mce-EMAIL,
#mc-embedded-subscribe, {
  font-family: var(--ft-body);
  font-feature-settings: "liga" 1;
  font-size: 20px;
  line-height: 1.30em;
}

.snip-layout {
  font-family: var(--ft-body) !important;
}

.ft-small {
  font-size: 14px;
  line-height: 20px;
}

h1,
h2,
h3,
.text-intro,
.text-intro p {
  font-family: var(--ft-head);
  line-height: 1.15em;
  margin-bottom: .75em;
  font-feature-settings: "liga" 0;
}

h1,
.month-head  {
  font-size: 46px;
  line-height: 1.15em;
}

.text-intro {
  margin-bottom: 1.5em;
}

h2,
.text-intro {
  font-size: 23px;
  line-height: 1.15em;
}

.section-about {
  font-size: 23px;
  font-family: var(--ft-head);
  line-height: 1.15em;
  font-feature-settings: "liga" 0;
}

h3 {
  font-size: 23px;
  line-height: 1.15em;
}

a,
a:visited {
  color: var(--black);
  text-decoration-color: var(--blue);
}

a:hover,
a:focus {
  color: var(--blue);
  text-decoration-color: var(--blue);
  cursor: pointer;
}

a.no-a-style,
a.no-a-style:hover,
a.no-a-style:focus {
  color: var(--black);
  text-decoration: none !important;
}

@media only screen and (min-width: 768px) {
  body {
    font-family: var(--ft-body);
    font-size: 24px;
    line-height: 1.15em;
  }

  .ft-small {
    font-size: 20px;
    line-height: 24px;
  }

  h1,
  .section-about,
  .month-head {
    font-size: 62px;
  }

  h2,
  .text-intro {
    font-size: 42px;
  }

  h3 {
    font-size: 28px;
  }
}

/* ----- Menu --------------------------------------------------------------- */

.menu-item,
.submenu-item,
#menu-toggle {
  font-family: var(--ft-head);
  font-feature-settings: "liga" 0;
}

.menu-item a,
#menu-toggle a {
  font-size: 20px;
  padding: .7em .75em .6em;
  text-decoration: none;
  color: var(--black);
}

.submenu-item a,
.submenu-item a:visited {
  font-size: 20px;
  /* padding: 1em .75em .85em .75em; */
  display: inline-block;
  color: var(--black);
  text-decoration: underline;
  text-decoration-color: var(--blue);
}

.menu-item a:hover,
.menu-item a:focus,
.menu-item a:visited {
  color: var(--black);
}

.submenu-item a:hover,
.submenu-item a:focus {
  color: var(--blue);
}

.submenu-item a:hover,
.submenu-item a:focus {
  color: var(--blue);
  text-decoration-color: var(--blue);
}

@media only screen and (min-width: 768px) {
  .menu-item a,
  .submenu-item a {
    font-size: 24px;
  }
  .menu-item a,
  #menu-toggle a {
    padding: .75em .75em .6em;
  }
}

/* ----- End Menu ----------------------------------------------------------- */

.button {
  padding: .85em .75em .85em .75em;
  display: inline-block;
  color: var(--black);
  margin-bottom: 1.5em;
}

.button:hover,
.button:focus,
.button:visited {
  color: var(--black);
}

/* ----- Section Tags ------------------------------------------------------- */
.section-tag {
  position: absolute;
  display: inline-block;
  font-family: var(--ft-body);
  border: var(--border);
  border-radius: .5em;
  padding: .25em .55em;
  margin: 2em 0 0 4.5vw;
}

@media only screen and (min-width: 768px) {
  .section-tag {
    margin: 1.5vw 0 0 1.5vw;
  }
}

/* ----- End Section Tags --------------------------------------------------- */

/* ----- Cards -------------------------------------------------------------- */
h2.month-head {
  margin-bottom: .45em;
}

.card-text h2 {
  margin-bottom: .25em;
}
/* ----- End Cards ---------------------------------------------------------- */

/* ----- Kirbytext ---------------------------------------------------------- */
.kt p {
  margin-bottom: 1em;
  line-height: 1.25em;
}

.kt h1,
.kt h2 {
  font-family: var(--ft-head);
  margin-bottom: .5em;
  font-feature-settings: "liga" 0;
}

.kt a {
  text-decoration: underline;
}

.kt > ul,
.kt > ol {
  margin-bottom: 1.5em;
}

.kt ul {
  /* list-style: none; */
  list-style: disc;
  list-style-position: inside;
}

.kt ul li {
  /* display: table-row; */
  margin-bottom: 1em;
}

/* .kt ul li:before {
  display: table-cell;
  text-align: right;
  padding: 0 .5em 0 0;
  content: "\25CF";
} */

pre, code {
  white-space: pre;
  white-space: pre-wrap;
  font-family: 'SF Mono';
  font-size: 95%;
}

.kt pre {
  border: 1px solid red;
  display: block;
  padding: .5em;
}

.ls--n:before {
  padding: 0;
  content: '';
}

.kt ol {
  list-style: none;
  display: table;
}

.kt ol li {
  display: table-row;
  counter-increment: number-list;
}

.kt ol li:before {
  content: counter(number-list) ".";
  display: table-cell;
  text-align: right;
  margin: 0 0 0 -2rem;
  width: 2rem;
  padding: 0 .5em 0 0;
}

@media all and (min-width: 500px) {

}
/* ----- End Kirbytext ------------------------------------------------------ */
