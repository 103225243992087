body {
  /* padding: 3vw; */
}

.main {
  padding: 5em 4vw 5em;
  overflow-x: hidden;
}

.main-home {
  padding: 4.8em 0 0 0;
}

.text {
  width: 100%;
}

@media only screen and (min-width: 980px) {
  .main {
    padding: 0 1.5vw 8em;
  }

  .main-home {
    padding: 0;
  }

  .text {
    width: 50%;
    width: 50vw;
    max-width: 800px;
    min-width: 648px;
  }
}

/* ----- Home --------------------------------------------------------------- */
.up-next {
  display: flex;           /* establish flex container */
  flex-direction: row;  /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center;
  padding-top: 4.5em;
}

.up-next .card {
  margin: 0 4.5vw 1em 4.5vw;
}

.section-feed {
  background-color: #f2f2f2;
}

@media only screen and (min-width: 768px) {
  .up-next {
    padding-top: 0;
  }

  .up-next .card {
    margin: 0 0 4.5em 0;
  }
}

/* ----- End Home ----------------------------------------------------------- */

/* ----- Calendar ----------------------------------------------------------- */
.nothing-on {
  height: 400px;
  height: 50vh;
}

.month {
  display: flex;
  flex-direction: column;
}

.month .card {
  margin-bottom: 2em;
  display: inline-block;
}

.month .card:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .month {
    flex-direction: row;
  }

  .month .card {
    margin-bottom: 2em;
  }

  .on-item {
    /* width: 44.5%; */
    flex: 1 1 auto;
  }

  .instagram-post:last-child {
    padding: 0;
  }

  .instagram-post:nth-child(even) {
    margin: 0 3em;
  }
}

/* ----- End Calendar ------------------------------------------------------- */

/* ----- Workshop ----------------------------------------------------------- */

.on-workshop-flex {
  display: flex;
  flex-direction: column-reverse;
}

.on-workshops {

}

.on-workshop-info {

}

@media only screen and (min-width: 768px) {
  .on-workshop-flex {
    flex-direction: row;
  }

  .on-workshops {
    position: -webkit-sticky;
    position: sticky;
    top: 6em;
    padding-left: 1em;
    width: calc(50% - 1em);

  }

  .on-workshop-info {
    width: calc(50% - 1em);
    padding-right: 1em;
    max-width: 800px;
    min-width: 648px;
  }
}

/* ----- End Workshop ------------------------------------------------------- */

/* ----- Hire --------------------------------------------------------------- */
.hire-items {
  display: flex;
  flex-direction: column;
  padding: 0 0 2.75em 0;
}

.hire-item {
  width: 100%;
  padding: 1em 3.25vw 1.5em;
  margin-bottom: 4.25vw;
  display: inline-block;
}

.hire-cost {
  padding-bottom: 1em;
  border-bottom: dashed grey 1px;
}

@media only screen and (min-width: 500px) {
  .hire-items {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .hire-items .hire-item {
    margin-right: 4.25vw;
  }

  .hire-items .hire-item:nth-child(2n) {
    margin-right: 0vw;
  }

  .hire-item {
    flex: 0 1 47.4%;
  }
}

@media only screen and (min-width: 1200px) {
  .hire-items .hire-item:nth-child(2n) {
    margin-right: 4.25vw;
  }

  .hire-items .hire-item:nth-child(3n) {
    margin-right: 0;
  }

  .hire-item {
    flex: 0 1 28.95%;
  }
}

/* ----- End Hire ----------------------------------------------------------- */

/* ----- Footer ------------------------------------------------------------- */
.footer {
  display: flex;
  flex-direction: column;
  border-top: var(--border);
}

.footer > div {
  width: 100%;
  height: 50vh;
}

.footer-connect-links,
.footer-logo,
#mc_embed_signup_scroll {
  display: flex;           /* establish flex container */
  flex-direction: row;  /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center;
  flex-wrap: wrap;
}

.footer-connect-links {
    padding: 0 0.5em;
    grid-gap: 0.5em;
    height: inherit;
    align-content: center;
}

#mc_embed_signup_scroll {
  height: auto;
}

.footer-logo img {
  width: 60%;
  padding-bottom: 3%;
  height: auto;
}

@media only screen and (min-width: 768px) {
  .footer {
    flex-direction: row;
  }

  .footer > div {
    width: 50%;
    flex: 1 1 auto;
    /* max-height: 400px; */
  }


}

/* ----- End Footer --------------------------------------------------------- */
